import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useLocation } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineShoppingCart } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { SellingDataRow } from "../../Components";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import Logo from "../../images/ttmm.png";
// import title from "../../images/ttTitle.png";
// import ttMap from "../../images/ttMap.png";
// import waterMark from "../../images/ttmmLogo.png";
import Radio from "@mui/material/Radio";
import AlertComponent from "../../Components/AlertComponent";
import subTitle from "../../images/subTitle.png";
import {
  addSaleProduct2Voucher,
  checkOutVoucher,
} from "../../ApiService/DailySaleSlice";
import { useGetProdcutByStoreQuery } from "../../ApiService/ProductApiSlice";
import { useAddDailySaleMutation } from "../../ApiService/DailySaleApiSlice";
import QtyDialog from "../../Components/QtyDialog";
import { Spinner } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { useGetSaleVoucherByIdQuery } from "../../ApiService/SaleVoucherApiSlice";
import { useGetCustomerQuery } from "../../ApiService/CustomerApiSlice";
import { useGetSaleVoucherByShopQuery } from "../../ApiService/SaleVoucherApiSlice";
import { useGetMontlyReportsQuery } from "../../ApiService/MontlyReportsApiSlice";
import { useGetDailyReportsQuery } from "../../ApiService/MontlyReportsApiSlice";

const DailySale = () => {
  const componentRef = useRef();
  const componentRefA4 = useRef();
  const componentRefA5 = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (!auth) {
      navigate("/login");
      // console.log("You are not logged in");
    }
  }, []);

  const dispatch = useDispatch();
  const saleVoucherList = useSelector(
    (state) => state.saleDetails.lstSaleVoucher
  );

  const shop = localStorage.getItem("shop_id");

  const { refetch: dailyReport } = useGetDailyReportsQuery(shop);
  const { refetch: MonthlyReport } = useGetMontlyReportsQuery(shop);

  const ProductList = useSelector((state) => state.productList.ProductList);

  const [selectedValuePrint, setSelectedValue] = useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const backToSelling = async (saleVoucher) => {
    // const url =`https://dev.futuretechmm.net/backend/public/api/active-voucher/${shop}/${saleVoucher}/6`;
    const url = `https://warehouse.futuretechmm.net/pos-vendor-backend/public/api/active-voucher/${shop}/${saleVoucher}/6`;
    const response = await fetch(url);
    console.log(response);
    navigate("/selling");
  };

  const {
    data: products,
    isLoading: productsLoading,
    isError: productsError,
    refetch,
  } = useGetProdcutByStoreQuery(shop); // use for Client Store prouduct list query

  const { refetch: billList } = useGetSaleVoucherByShopQuery(shop);

  const { refetch: VoucherRefetch } = useGetSaleVoucherByIdQuery(shop);
  const { refetch: CustomerRefetch } = useGetCustomerQuery(shop);

  const [addDailySale, result] = useAddDailySaleMutation();

  const { state } = useLocation();

  const voucherList = saleVoucherList.filter(
    (voucher) => voucher.id === state.voucher.id
  );

  const [lstSales, setLstSales] = useState(
    voucherList?.length > 0 ? voucherList[0].product : []
  );
  const [value, setValue] = useState(null);
  const [clear, setClear] = useState(uuidv4());
  const [openModal, setOpenModal] = useState(false);
  const [ProductLst, setLstProduct] = useState([]);

  const [total, setTotal] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [bill, setBill] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [alertOpen, setAlertOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const getPageMargins = () => {
    return `@page { margin: 20px !important; }`;
  };

  const handleClick = () => {
    setAlertOpen(true);
  };

  const head = [
    {
      id: 1,
      title: "Barcode",
    },
    {
      id: 2,
      title: "Product Name",
    },
    {
      id: 3,
      title: "Qty",
    },
    {
      id: 4,
      title: "Unit",
    },
    {
      id: 5,
      title: "Price",
    },
    {
      id: 6,
      title: "Amount",
    },
    {
      id: 7,
      title: "Action",
    },
  ];

  // ********* Add Product to AutoComplete Box *************
  useEffect(() => {
    if (!productsLoading) {
      const lstProduct = products?.data.map((cus) => ({
        ...cus,
        label: cus.name,
      }));

      setLstProduct([
        ...lstProduct?.map((p) => {
          return {
            ...p.product,
            label:
              p.product.name +
              " - (" +
              p.qty +
              " ) - " +
              Math.ceil(
                ((p.product.purchase.price +
                  (p.product.purchase.price * p.product.remark) / 100) *
                  p.product.currency.ChangePrice) /
                  100
              ) *
                100 +
              " Ks ",
          };
        }),
      ]);
    } else if (productsError) {
      navigator("/net-err");
    }
    // dispatch(getProductByVoucher({ id: state.voucher.id }));
  }, [products]);

  useEffect(() => {
    setTotal(lstSales?.reduce((a, v) => (a = a + v.total), 0));
    localStorage.getItem("auth") &&
      dispatch(
        addSaleProduct2Voucher({
          id: state.voucher.id,
          product: lstSales,
        })
      );
  }, [lstSales]);

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      setCashAmount(total - discount);
    }
  }, [total, discount]);

  useEffect(() => {
    const netAmount = total - discount;
    const netBill = netAmount - cashAmount;
    setBill(netBill);
  }, [cashAmount]);

  //************ Selling Item List ***************** */
  const handlerSaleDetails = (val, e) => {
    if (val === undefined) return;

    const checkMinQty = [
      ...products.data?.filter((product) => product.product_id === val.id),
    ];
    const checkItem = [...lstSales?.filter((product) => product.id === val.id)];
    if (checkMinQty[0].qty < 1) return;
    // console.log(lstSales);
    let pQty = 1;

    console.log(checkItem);
    if (checkItem?.length < 1) {
      pQty = 1;
      const saleItem = {
        id: val.id,
        barcode: val.barcode,
        product_name: val.name,
        qty: pQty,
        unit_name: val.unit.name,
        img: val.img,
        sell_price:
          Math.ceil(
            ((val.purchase.price + (val.purchase.price * val.remark) / 100) *
              val.currency.ChangePrice) /
              100
          ) * 100,
        total:
          pQty *
          Math.ceil(
            ((val.purchase.price + (val.purchase.price * val.remark) / 100) *
              val.currency.ChangePrice) /
              100
          ) *
          100,
        created_at: val.created_at,
        status: 0,
      };
      setLstSales([...lstSales, saleItem]);
    } else {
      pQty = checkItem[0]?.qty + 1;
      setLstSales([
        ...lstSales?.map((saleitem) =>
          saleitem.id === val.id
            ? {
                ...saleitem,
                qty: pQty,
                total:
                  pQty *
                  parseInt(val.purchase.price) *
                  parseInt(val.currency.ChangePrice),
              }
            : { ...saleitem }
        ),
      ]);
      // console.log(pQty);
    }
    setClear(uuidv4());
    setCashAmount(total - discount);
  };

  // *******************update quantity*******************
  const updateQty = (qty) => {
    if (parseInt(qty) === 0) return;

    const checkMinQty = [
      ...products.data?.filter(
        (product) => product.product_id === lstSales[index].id
      ),
    ];

    if (checkMinQty[0].qty < qty) return;

    const index = lstSales.length - 1;
    setLstSales([
      ...lstSales.map((saleitem) =>
        saleitem.id === lstSales[index].id
          ? {
              ...saleitem,
              qty: qty,
              total: qty * saleitem.sell_price,
            }
          : { ...saleitem }
      ),
    ]);
    dispatch(
      addSaleProduct2Voucher({ id: state.voucher.id, product: lstSales })
    );
    setCashAmount(total - discount);
  };

  const increaseQty = (index, qty) => {
    const incQty = qty + 1;
    const checkMinQty = [
      ...products.data?.filter(
        (product) => product.product_id === lstSales[index].id
      ),
    ];
    // console.log(checkMinQty[0])
    if (checkMinQty[0].qty < incQty) return;
    // console.log(checkMinQty)

    setLstSales([
      ...lstSales.map((saleitem) =>
        saleitem.id === lstSales[index].id
          ? {
              ...saleitem,
              qty: incQty,
              total: incQty * saleitem.sell_price,
            }
          : { ...saleitem }
      ),
    ]);
    dispatch(
      addSaleProduct2Voucher({ id: state.voucher.id, product: lstSales })
    );
    setCashAmount(total - discount);
  };

  const decreaseQty = (index, qty) => {
    if (qty < 2) return;
    const incQty = qty - 1;
    setLstSales([
      ...lstSales.map((saleitem) =>
        saleitem.id === lstSales[index].id
          ? {
              ...saleitem,
              qty: incQty,
              total: incQty * saleitem.sell_price,
            }
          : { ...saleitem }
      ),
    ]);
    dispatch(
      addSaleProduct2Voucher({ id: state.voucher.id, product: lstSales })
    );
    setCashAmount(total - discount);
  };

  //*******************delete product Item ************************ */

  const removeProduct = (item) => {
    console.log(lstSales);
    setLstSales([...lstSales.filter((saleitem) => saleitem.id !== item.id)]);
    // console.log(item);
    dispatch(
      addSaleProduct2Voucher({ id: state.voucher.id, product: lstSales })
    );
    setCashAmount(total - total * (state.voucher.discount / 100));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintA4 = useReactToPrint({
    content: () => componentRefA4.current,
    pageStyle: () => getPageMargins,
  });

  const handlePrintA5 = useReactToPrint({
    content: () => componentRefA4.current,
    pageStyle: () => getPageMargins,
  });


  const AddDailySaleToServer = async () => {
    if (result.isLoading) return; // fix bug double entry to sale records

    if (total == 0) return; // fix bug none entry to sale records

    const shop_id = localStorage.getItem("shop_id");
    const user = JSON.parse(localStorage.getItem("user"));
    const body = {
      shop_id: shop_id,
      user_id: user.id,
      sale_voucher_id: state.voucher.id,
      total: total,
      discount: discount,
      bill: bill,
      status: 1,
      note: bill > 0 ? "1" : "0", // bill  ရှိရင် 1 , မရှိရင် 0
      lstProduct: lstSales,
    };

    await addDailySale(body);
    // await CustomerRefetch();

    // VoucherRefetch();
    // MonthlyReport();
    // billList();
    // dailyReport();
    // navigate("/selling");
    // await refetch();
    // dispatch(checkOutVoucher({ id: state.voucher.id }));
  };

  useEffect(() => {
    if (result.isSuccess) {
      CustomerRefetch();
      VoucherRefetch();
      MonthlyReport();
      billList();
      dailyReport();
      navigate("/selling");
      refetch();
      dispatch(checkOutVoucher({ id: state.voucher.id }));
      selectedValuePrint == "a" ? handlePrintA4() : selectedValuePrint == "b" ? handlePrintA5() : handlePrint();
      handleClick();
    } else if (result.isError) {
      // backToSelling();
      handleClick();
    }
  }, [result]);

  return (
    <>
      <div
        style={{ display: "none" }} // This make ComponentToPrint show   only while printing
      >
        {localStorage.getItem("auth") ? (
          <PrintComponentA4
            ref={componentRefA4}
            voucher={{
              cashier: state.voucher?.customer?.name,
              adds: state.voucher?.customer?.address,
              id: state.voucher?.id,
              phone: state.voucher?.customer?.phone,
            }}
            items={lstSales}
            summary={{
              total: total,
              bill: bill,
              oldBill: state.voucher?.bill,
              discount: discount,
              cash: cashAmount,
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div
        style={{ display: "none" }} // This make ComponentToPrint show   only while printing
      >
        {localStorage.getItem("auth") ? (
          <PrintComponentA5
            ref={componentRefA5}
            voucher={{
              cashier: state.voucher?.customer?.name,
              adds: state.voucher?.customer?.address,
              id: state.voucher?.id,
              phone: state.voucher?.customer?.phone,
            }}
            items={lstSales}
            summary={{
              total: total,
              bill: bill,
              oldBill: state.voucher?.bill,
              discount: discount,
              cash: cashAmount,
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="relative h-full">
        {!productsLoading ? (
          <div className="flex flex-col text-xs">
            <div className="flex flex-row justify-between items-center">
              <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={() => backToSelling(state.voucher?.id)}
              >
                Daily Sale
              </Button>

              <div className="border-[1px] rounded-md p-2 w-fit">
                <Autocomplete
                  key={clear}
                  id="combo-box"
                  autoHighlight
                  selectOnFocus
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  options={ProductLst}
                  // options={top100Films}
                  sx={{ width: 400 }}
                  onChange={(event, newValue) => {
                    // console.log(newValue)
                    if (newValue !== undefined) {
                      handlerSaleDetails(newValue, event);
                    }
                  }}
                  // renderOption={(e)=>{
                  //   console.log(e)
                  // }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Item"
                        variant="outlined"
                        autoFocus
                        size="small"
                        onKeyUp={(e) => {
                          if (e.key === "Enter" && e.target.value === "") {
                            // setOpenModal(true);
                            dispatch(
                              addSaleProduct2Voucher({
                                id: state.voucher.id,
                                product: lstSales,
                              })
                            );
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowRight" && e.target.value === "") {
                            setOpenModal(true);
                          } else if (
                            e.key === "Enter" &&
                            (e.target.value !== "" ** e.target.value) !==
                              undefined
                          ) {
                            // handlerSaleDetails(e);
                          }
                        }}
                      />
                    );
                  }}
                />
              </div>

              <div className="flex flew-row">
                <div>
                  <label>A4</label>
                  <Radio
                    checked={selectedValuePrint === "a"}
                    onChange={handleChange}
                    value="a"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />
                </div>
                <div>
                  <label>A5</label>
                  <Radio
                    checked={selectedValuePrint === "b"}
                    onChange={handleChange}
                    value="b"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                  />
                </div>
                <div>
                  <label>Slip</label>
                  <Radio
                    checked={selectedValuePrint === "s"}
                    onChange={handleChange}
                    value="s"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "S" }}
                  />
                </div>
              </div>

              <div className="flex flex-row gap-2">
                <p className="px-4 py-2 bg-gray-700 text-gray-300 rounded-lg w-fit gap-2 flex-row flex">
                  Daily Sales {state.voucher.id}
                </p>
                <p className="px-4 py-2 bg-gray-700 text-gray-300 rounded-lg w-fit">
                  Cusotmer Name : <span>{state.voucher?.customer?.name}</span>
                </p>
              </div>
            </div>
            {/* DataTable Row */}
            <div className="my-2">
              <div>
                <SellingDataRow
                  head={head}
                  rows={lstSales}
                  removeItem={(val) => removeProduct(val)}
                  increaseQty={(index, qty) => increaseQty(index, qty)}
                  decreaseQty={(index, qty) => decreaseQty(index, qty)}
                />
              </div>
            </div>

            {/* Check Out Section */}
            <div className="p-2 gap-2 grid grid-cols-6 absolute bottom-0 right-0 z-20 shadow-xl bg-blue-gray-100 rounded-lg">
              <TextField
                size="small"
                margin="dense"
                id="amout"
                label="Total Amount"
                type="number"
                fullWidth
                variant="outlined"
                value={total}
                disabled
              />
              <TextField
                size="small"
                margin="dense"
                id="discount"
                label="Discount"
                type="number"
                fullWidth
                variant="outlined"
                value={discount}
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    setDiscount(e.target.value);
                  } else {
                    return;
                  }
                }}
              />
              <TextField
                size="small"
                margin="dense"
                id="bill_invoice"
                label="Bill"
                type="number"
                fullWidth
                variant="outlined"
                disabled
                value={bill}
              />
              <TextField
                size="small"
                margin="dense"
                id="tax"
                label="Tax"
                type="number"
                fullWidth
                disabled
                value={state.voucher.discount}
                variant="outlined"
              />
              <TextField
                size="small"
                margin="dense"
                id="cash"
                label="Cash"
                type="number"
                fullWidth
                variant="outlined"
                value={cashAmount}
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    const netAmount = total - discount;
                    const netBill = netAmount - e.target.value;
                    setBill(netBill);
                    setCashAmount(e.target.value);
                  } else {
                    return;
                  }
                }}
              />
              <div
                className="flex flex-row justify-center items-center rounded-lg text-gray-900 border-2 border-blue-600 mt-2 cursor-pointer hover:bg-blue-600 hover:text-white hover:border-blue-600"
                onClick={AddDailySaleToServer}
              >
                <span className="text-sm mx-4 font-semibold"> Check Out</span>
                {result.isLoading ? (
                  <Spinner />
                ) : (
                  <MdOutlineShoppingCart size={16} />
                )}
              </div>
            </div>
            {/*  Qty Dialog */}
            <div>
              <QtyDialog
                open={openModal}
                handleClose={() => setOpenModal(false)}
                handlerUpdate={updateQty}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-row w-full h-screen justify-center items-center">
            <Spinner size={30} />
          </div>
        )}
      </div>

      <div
        style={{ display: "none" }} // This make ComponentToPrint show   only while printing
      >
        {localStorage.getItem("auth") ? (
          <PrintComponent
            ref={componentRef}
            voucher={{
              cashier: state.voucher?.customer?.name,
              adds: state.voucher?.customer?.address,
              id: state.voucher?.id,
              phone: state.voucher?.customer?.phone,
            }}
            items={lstSales}
            summary={{
              total: total,
              bill: bill,
              oldBill: state.voucher?.bill,
              discount: discount,
              cash: cashAmount,
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <AlertComponent
        open={alertOpen}
        handleClose={handleClose}
        result={result}
      />
    </>
  );
};

const PrintComponent = forwardRef((props, ref) => {
  const { voucher, items, summary } = props;
  // console.log(props);
  // console.log("Testing");
  // const [i, setI] = useState({});
  var today = new Date();
  const date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    "-" +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  const shop = JSON.parse(localStorage.getItem("shopdetails"));
  const user = JSON.parse(localStorage.getItem("user"));

  const phoneList = shop.phone.split(",");
  return (
    <>
      <div
        id="divToPrint"
        className="flex flex-col w-[303px] h-fit text-center"
        ref={ref}
      >
        <div className="flex flex-row w-full h-fit pt-2 justify-center items-center">
          {shop.id == 1 ? (
            <img src={Logo} alt="slip" width={150} height={50} />
          ) : (
            <>
              <img src={Logo} alt="slip" width={150} height={50} />
            </>
          )}
        </div>

        <div className="flex flex-col w-full h-fit py-2 justify-center items-center">
          {/* <p className="font-bold font-poppins text-base">ပေါ်ပြူလာ</p> */}
          <p className="font-bold font-poppins text-xs px-8">
            စပါးရိတ်သိမ်းခြွေလှေ့စက် ၊ ထွန်စက် နှင့် အပိုပစ္စည်း အရောင်းဆိုင်
          </p>
          {/* <p className="font-bold font-poppins text-xs">
            ဆိုင်အမှတ် ပ-၂၄၅၊ နေပြည်တော်မြို့မဈေးမြောက်ဘက်တန်း၊
          </p> */}

          <div className="grid grid-cols-3 gap-1">
            {phoneList.map((phone) => (
              <p
                className="font-poppins text-xs text-balance w-full text-center justify-center items-center mx-auto"
                key={uuidv4().toString()}
              >
                {phone}
              </p>
            ))}
          </div>
          <p className="font-bold font-poppins text-sm">{shop.address}</p>
        </div>

        <div className="flex flex-row w-full h-fit px-2 justify-between items-start">
          <div className="flex flex-col justify-between items-start">
            <p className="font-poppins text-base">
              <span className="text-base pr-6">Date - </span>
              {today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate()}
            </p>
            <p className="font-poppins text-base">
              <span className="text-base pr-6">Voucher ID</span>
              {voucher.id}
            </p>
            <p className="font-poppins text-base">
              <span className="text-base pr-6">Cashier - </span>
              {user.name}
            </p>
          </div>
          <div className="flex flex-col justify-between">
            <p className="font-poppins text-base">
              <span className="text-base pr-6">အမည်</span>
              {voucher.cashier}
            </p>
            <p className="font-poppins text-base">
              <span className="text-base pr-6">လိပ်စာ</span>
              {voucher.adds}
            </p>
          </div>
        </div>

        <div className="flex flex-row w-full h-fit px-2 pt-4">
          <div className="flex-1 text-xs text-start">အမျိုးအမည်</div>
          <div className="w-8 text-xs">ဦးရေ</div>
          <div className="text-xs w-16">နှုန်း</div>
          <div className="text-xs w-20">သင့်ငွေ</div>
        </div>
        <div>
          --------------------------------------------------------------
        </div>

        {items.map((item, index) => (
          <div className="flex flex-row w-full h-fit py-2 pr-2" key={uuidv4()}>
            <div className="flex-1 text-xs">{item.product_name}</div>
            <div className="w-8 text-xs">{item.qty}</div>
            <div className="text-xs w-16">{item.sell_price}</div>
            <div className="text-xs w-20">{item.total}</div>
          </div>
        ))}

        <div>-------------------------------------------------------------</div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>ကျသင့်ငွေ </span>
          {summary.total} ကျပ်
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>လျော့ပေးငွေ </span>
          {summary.discount} ကျပ်
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>ပေးချေငွေ </span>
          {Math.floor(summary.cash)} ကျပ်
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>ယခင် အကြွေးကျန် </span>
          {summary.oldBill} ကျပ်
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>ယခု အကြွေးကျန် </span>
          {summary.bill} ကျပ်
        </div>

        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>စုစုပေါင်း အကြွေးကျန် </span>
          {parseInt(summary.bill) + parseInt(summary.oldBill)} ကျပ်
        </div>
        <div className="w-full h-fit text-xs text-center mt-4">
          ⚠️ ဝယ်ယူပြီး ပစ္စည်းပြန်မလဲပေးပါ။ ⚠️
        </div>
        <div className="w-full h-fit text-xs text-center mt-4">
          🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
        </div>
        <div className="flex flex-row w-full py-2 px-2 justify-between font-poppins text-xs mb-10">
          <span>Print Time </span>
          {date}
        </div>
        <div>
          --------------------------
          <span className="transform rotate-270 font-bold">✂</span>
          ---------------------------------
        </div>
      </div>
    </>
  );
});

const PrintComponentA4 = forwardRef((props, ref) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { voucher, items, summary } = props;
  // console.log(props);
  // console.log("Testing");
  // const [i, setI] = useState({});
  var today = new Date();
  const date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    "-" +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  const shop = JSON.parse(localStorage.getItem("shopdetails"));

  function currencyFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
  }
  const phoneList = shop.phone.split(",");
  const getPageMargins = () => {
    return `@page { margin: 20px !important; }`;
  };

  return (
    <>
      <div
        id="divToPrint"
        className="flex flex-col h-fit text-center relative z-50"
        ref={ref}
      >
        <style>{getPageMargins()}</style>
        <div className="flex flex-col w-full h-fit justify-center items-center sm:text-xs">
          <div className="flex flex-row justify-center w-full items-center">
            {/* <img src={ttMap} alt="logo" width={80} height={80} className="" /> */}
            <div className="w-full">
              <div className="flex flex-row w-full h-fit justify-center items-center">
                <img src={Logo} alt="slip" className="h-32 w-full" />
              </div>
              <div className="flex flex-row w-full h-fit justify-center items-center -mt-2">
                <img src={subTitle} alt="slip" className=" h-16 w-full" />
              </div>
              <div className="grid grid-cols-5 gap-1">
                {phoneList.map((phone) => (
                  <p
                    key={uuidv4().toString()}
                    className="font-bold font-poppins text-sm text-balance w-full text-center justify-center items-center mx-auto"
                  >
                    {phone}
                  </p>
                ))}
              </div>
              <p className="font-bold font-poppins text-2xl">{shop.address}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full h-fit  justify-between items-start">
          <div className="flex flex-col justify-between items-start w-1/3">
            <p className="font-poppins text-sm flex flex-row w-full  justify-between items-center">
              <span className="text-sm pr-2">Voucher ID</span>
              <p>{voucher.id}</p>
            </p>
            <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
              <span className="text-sm pr-2">Cashier </span>
              {user.name}
            </p>
            <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
              <span className="text-sm">Date </span>
              <p>
                {today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate()}
              </p>
            </p>
          </div>

          <div className="flex flex-row justify-end grow-0">
            <div className="flex flex-col">
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">အမည်</span>
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">လိပ်စာ</span>
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">
                  ဖုန်းနံပါတ်
                </span>
              </p>
            </div>
            <div className="flex-1 flex-col justify-between">
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.cashier}
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.adds}
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.phone}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full mt-1">
          <table className="w-full text-sm text-left text-black border border-black border-collapse py-5">
            <thead className="text-lg text-center text-white uppercase !bg-blue-600 my-header">
              <tr>
                <th
                  scope="col"
                  className=" text-lg border border-black border-collapse"
                >
                  No.
                </th>
                <th
                  scope="col"
                  className="px-6 text-lg border border-black border-collapse"
                >
                  Product Name
                </th>
                <th
                  scope="col"
                  className="text-lg border border-black border-collapse"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="px-3 border border-black border-collapse text-lg"
                >
                  Price
                </th>

                <th
                  scope="col"
                  className="px-4 border border-black border-collapse text-lg"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr
                  className="border border-black border-collapse"
                  key={uuidv4().toString()}
                >
                  <td
                    scope="row"
                    className="px-2 py-1 border border-black border-collapse text-center"
                  >
                    {index + 1}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse">
                    {item.product_name}
                  </td>
                  <td className="py-1 border border-black border-collapse text-center">
                    {item.qty}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-right">
                    {currencyFormat(parseInt(item.sell_price))}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-right">
                    {currencyFormat(parseInt(item.total))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className=" flex flex-row w-full justify-end text-base">
          <div className=" flex flex-col w-1/2 justify-end items-end">
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="ml-16 text-start w-[60%]">စုစုပေါင်းငွေ </span>{" "}
              {summary.total}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="ml-16 text-start w-[60%]">လျော့ပေးငွေ </span>
              {summary.discount}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="ml-16 text-start w-[60%]">ပေးချေငွေ </span>
              {summary.cash}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="ml-16 text-start w-[60%]">ယခင်ကြွေးကျန် </span>{" "}
              {summary.oldBill}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="ml-16 text-start w-[60%]">ယခုကြွေးကျန် </span>
              {summary.bill}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="ml-16 text-start w-[60%]">
                စုစုပေါင်းကြွေးကျန်{" "}
              </span>{" "}
              {parseInt(summary.bill) + parseInt(summary.oldBill)}
            </div>
          </div>
        </div>

        <div className="w-full h-fit text-lg text-center mt-4">
          🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
        </div>

        <div className="w-full h-fit text-lg text-center mt-4">
          ⚠️ ဝယ်ယူပြီး ပစ္စည်းပြန်မလဲပေးပါ။ ⚠️
        </div>

        <div className="flex flex-row w-full justify-between font-poppins text-base">
          <span>Print Time </span>
          {date}
        </div>
        <div>
          --------------------------
          <span className="transform rotate-270 font-bold">✂</span>
          ---------------------------------
        </div>
      </div>
    </>
  );
});

const PrintComponentA5 = forwardRef((props, ref) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { voucher, items, summary } = props;
  // console.log(props);
  // console.log("Testing");
  // const [i, setI] = useState({});
  var today = new Date();
  const date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    "-" +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  const shop = JSON.parse(localStorage.getItem("shopdetails"));

  function currencyFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
  }
  const phoneList = shop.phone.split(",");
  const getPageMargins = () => {
    return `@page { margin: 20px !important; }`;
  };

  return (
    <>
      <div
        id="divToPrint"
        className="flex flex-col h-fit text-center relative z-50"
        ref={ref}
      >
        <style>{getPageMargins()}</style>
        <div className="flex flex-col w-full h-fit py-2 justify-center items-center sm:text-xs">
          <div className="flex flex-row w-full justify-center items-center">
            {/* <img src={ttMap} alt="logo" width={80} height={80} className="" /> */}
            <div className="w-full">
              <div className="flex flex-row w-full h-fit justify-center items-center">
                <img src={Logo} alt="slip" className="h-24 w-full" />
              </div>
              {/* <p className="font-bold font-poppins text-[20px]">
                စပါးရိတ်သိမ်းခြွေလှေ့စက်၊ထွန်စက်နှင့်အပိုပစ္စည်းအရောင်းဆိုင်
              </p> */}
              <div className="flex flex-row w-full h-fit justify-center items-center">
                <img src={subTitle} alt="slip" className=" h-10 w-full" />
              </div>
              <div className="grid grid-cols-5 gap-1 mt-2">
                {phoneList.map((phone) => (
                  <p
                    key={uuidv4().toString()}
                    className="font-bold font-poppins text-[12px] text-balance w-full text-center justify-center items-center mx-auto"
                  >
                    {phone}
                  </p>
                ))}
              </div>
              <p className="font-bold font-poppins text-xl pt-1">
                {shop.address}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full h-fit  justify-between items-start">
          <div className="flex flex-col justify-between items-start w-1/3">
            <p className="font-poppins text-sm flex flex-row w-full  justify-between items-center">
              <span className="text-sm pr-2">Voucher ID</span>
              <p>{voucher.id}</p>
            </p>
            <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
              <span className="text-sm pr-2">Cashier </span>
              {user.name}
            </p>
            <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
              <span className="text-sm">Date </span>
              <p>
                {today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate()}
              </p>
            </p>
          </div>

          <div className="flex flex-row justify-end grow-0">
            <div className="flex flex-col">
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">အမည်</span>
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">လိပ်စာ</span>
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">
                  ဖုန်းနံပါတ်
                </span>
              </p>
            </div>
            <div className="flex-1 flex-col justify-between">
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.cashier}
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.adds}
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.phone}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full mt-1">
          <table className="w-full text-sm text-left text-black border border-black border-collapse py-5">
            <thead className="text-lg text-center text-white uppercase !bg-blue-600 my-header">
              <tr>
                <th
                  scope="col"
                  className=" text-lg border border-black border-collapse"
                >
                  No.
                </th>
                <th
                  scope="col"
                  className="px-6 text-lg border border-black border-collapse"
                >
                  Product Name
                </th>
                <th
                  scope="col"
                  className="text-lg border border-black border-collapse"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="px-3 border border-black border-collapse text-lg"
                >
                  Price
                </th>

                <th
                  scope="col"
                  className="px-4 border border-black border-collapse text-lg"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr
                  className="border border-black border-collapse"
                  key={uuidv4().toString()}
                >
                  <td
                    scope="row"
                    className="px-2 py-1 border border-black border-collapse text-center"
                  >
                    {index + 1}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse">
                    {item.product_name}
                  </td>
                  <td className="py-1 border border-black border-collapse text-center">
                    {item.qty}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-right">
                    {currencyFormat(parseInt(item.sell_price))}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-right">
                    {currencyFormat(parseInt(item.total))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className=" flex flex-row w-full justify-end text-base">
          <div className=" flex flex-col w-1/2 justify-end items-end">
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="ml-16 text-start w-[60%]">စုစုပေါင်းငွေ </span>{" "}
              {summary.total}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="ml-16 text-start w-[60%]">လျော့ပေးငွေ </span>
              {summary.discount}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="ml-16 text-start w-[60%]">ပေးချေငွေ </span>
              {summary.cash}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="ml-16 text-start w-[60%]">ယခင်ကြွေးကျန် </span>{" "}
              {summary.oldBill}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="ml-16 text-start w-[60%]">ယခုကြွေးကျန် </span>
              {summary.bill}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="ml-16 text-start w-[60%]">
                စုစုပေါင်းကြွေးကျန်{" "}
              </span>{" "}
              {parseInt(summary.bill) + parseInt(summary.oldBill)}
            </div>
          </div>
        </div>

        <div className="w-full h-fit text-lg text-center mt-4">
          🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
        </div>

        <div className="w-full h-fit text-lg text-center mt-4">
          ⚠️ ဝယ်ယူပြီး ပစ္စည်းပြန်မလဲပေးပါ။ ⚠️
        </div>

        <div className="flex flex-row w-full justify-between font-poppins text-base">
          <span>Print Time </span>
          {date}
        </div>
        <div>
          --------------------------
          <span className="transform rotate-270 font-bold">✂</span>
          ---------------------------------
        </div>
      </div>
    </>
  );
});

export default DailySale;
