import React, { useState } from "react";
import { Button, Spinner } from "@material-tailwind/react";
import { BsPlusCircle } from "react-icons/bs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MyDialog } from "../Components";
import { Link, useNavigate } from "react-router-dom";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { addSaleVoucher } from "../ApiService/DailySaleSlice";

import { useSelector } from "react-redux";
import { useGetCustomerQuery } from "../ApiService/CustomerApiSlice";
import { useGetSaleVoucherByIdQuery } from "../ApiService/SaleVoucherApiSlice";
import {
  useGetDailySaleQuery,
  useAddDailySaleVoucherMutation,
} from "../ApiService/DailySaleApiSlice";

// const cards = [];

const SellingPage = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const voucherList = useSelector((state) => state.saleDetails?.lstSaleVoucher);
  // console.log(voucherList);

  const shop_id = localStorage.getItem("shop_id");
  const {
    data: sale,
    isLoading: saleLoading,
    isSuccess,
  } = useGetDailySaleQuery();

  const {
    data: customer,
    isLoading: customerLoading,
    isSuccess: customerSuccess,
  } = useGetCustomerQuery(shop_id);

  const {
    data: voucher,
    isLoading: voucherLoading,
    isSuccess: voucherSuccess,
    refetch,
  } = useGetSaleVoucherByIdQuery(localStorage.getItem("shop_id"));

  const shopId = localStorage.getItem("shop_id");
  const [addVoucher, result] = useAddDailySaleVoucherMutation(
    localStorage.getItem("shop_id") == null ? 1 : localStorage.getItem("shop_id")
  );

  const [openModal, setOpenModal] = useState(false);
  const [lstVoucher, setLstVoucher] = useState(voucherList);
  // const [customerList, setCustomerList] = useState(customer?.data.map((cus)=> ({...cus,label:cus.name})))

  const VoucherList = async (item) => {
    
    console.log(item);
    const pId = 1;
    const saleVoucher = {
      customer_id: item.customer.id,
      bill: item.customer.note,
      store_voucher_id: pId,
      shop_id: localStorage.getItem("shop_id"),
      discount: item.discount,
      status: "0",
      note: "0",
    };

    await addVoucher(saleVoucher);
    refetch();
  };

  const addToStore = async (saleVoucher) =>  {
    

    // const url =`https://dev.futuretechmm.net/backend/public/api/active-voucher/${shopId}/${saleVoucher.id}/1`;

    const url =`https://warehouse.futuretechmm.net/pos-vendor-backend/public/api/active-voucher/${shopId}/${saleVoucher.id}/1`;
    try {
      await refetch();
      const response = await fetch(url);
      const json = await response.json();
      console.log(json);
      let presentVoucher = voucherList.filter(v => v.id == saleVoucher.id);
      console.log(presentVoucher);
      if(json.data == 1){ //pending
        console.log("active voucher");
        navigate("/selling");
        return;
      }else if(json.data == 2){ // selling successfully
        await refetch();
        navigate("/selling");
        return;
      }
    } catch (error) {
      console.log("error", error);
    }

    const stateSaleVoucher = { ...saleVoucher, product: [] };

    dispatch(addSaleVoucher(stateSaleVoucher));
  };

  return (
    <>
      <div className="flex flex-col gap-4 w-full ">
        <div className="flex flex-row w-full justify-between">
        <Button
            variant="outlined"
            className="flex items-center gap-3 hover:bg-blue-500 hover:text-white"
            onClick={refetch}
          >
            Check Voucher
          </Button>

          <Button
            variant="outlined"
            className="flex items-center gap-3 hover:bg-blue-500 hover:text-white"
            onClick={() => setOpenModal(!openModal)}
          >
            Create New
            {result.isLoading ? (
              <Spinner />
            ) : (
              <BsPlusCircle className="h-6 w-6" />
            )}
          </Button>
        </div>
        {/* <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-8 gap-4">
          {voucherList.map((card, key) => (
            <Card
              key={card.customer.name}
              variant="outlined"
              sx={{ maxWidth: 150 }}
              className="relative"
            >
              <Link to="/daily-sales" state={{ voucher: card }}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      <IoDocumentTextOutline size={110} color="gray" />
                    </Typography>
                    <div className="absolute -right-10 top-5 bg-yellow-400 w-full text-green-800 rotate-45 !overflow-visible text-center text-sm">
                      {card.date}
                    </div>
                    <div className="text-center flex-row flex justify-between">
                      <Typography variant="body2" color="text.secondary">
                        {card.customer.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {card.discount} %
                      </Typography>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          ))}
        </div> */}
        <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-8 gap-4 mt-2">
          {voucher?.data.map(
            (card, key) =>
              card.status === "0" && (
                <Card
                  key={card.id}
                  variant="outlined"
                  sx={{ maxWidth: 150 }}
                  className="relative"
                  onClick={() => addToStore(card)}
                >
                  <Link to="/daily-sales" state={{ voucher: card }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          <IoDocumentTextOutline size={110} color="gray" />
                        </Typography>
                        <div
                          className={`absolute -right-10 top-5 ${
                            card.status === 0
                              ? " bg-yellow-300 "
                              : " bg-green-300 "
                          }} w-full text-green-800 rotate-45 !overflow-visible text-center text-sm`}
                        >
                          {card.created_at.split("T")[0]}
                        </div>
                        <div className="text-center flex-row flex justify-between">
                          <Typography variant="body2" color="text.secondary">
                            {card.customer?.name}
                          </Typography>
                          {/* <Typography variant="body2" color="text.secondary">
                            {card.discount} %
                          </Typography> */}
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                </Card>
              )
          )}
        </div>
      </div>
      {!customerLoading ? (
        <MyDialog
          open={openModal}
          handleClose={() => setOpenModal(false)}
          handlerVoucher={VoucherList}
          customerlist={customer?.data.map((cus) => ({
            ...cus,
            label: cus.name,
          }))}
        />
      ) : (
        <div className="flex flex-row w-full h-full justify-center items-center">
          <Spinner color="purple" className="w-16 h-16" />
        </div>
      )}
    </>
  );
};

export default SellingPage;
