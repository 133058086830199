import { ApiSlice } from "./ApiSlice";


export const ProductApiSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({

        getProdcutByStore: builder.query({
            query: (id) =>`/shop-store/product/${id}`,
            providesTags: ['Product'],
        }),
        getProdcut: builder.query({
            query: () =>`/product`,
            providesTags: ['Product'],
        }),
        addRefund: builder.mutation({
            query: (body) => ({
                url:  `/sale-return/${body.shop_id}`,
                method: 'POST',
                body,
            }),
        }),
        getRefundRecord: builder.query({
            query: (id) =>`/sale-return-record/${id}`,
        }),
        getRefundRecordByDate:builder.mutation({
            query: (body) => ({
                url:  `/sale-return-record`,
                method: 'POST',
                body,
            }),
        }),
        getCheckInOut:builder.mutation({
            query: (body) => ({
                url:  `/account/shop/prouduct-list`,
                method: 'POST',
                body,
            }),
        }),

        // getProdcutByStore: builder.query({
        //     query: (id) =>`/warehouse-item/${id}`,
        //     providesTags: ['Product'],
        // }),
        // addRefund: builder.mutation({
        //     query: (body) => ({
        //         url:  `/warehouse-refund/${body.shop_id}`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // getRefundRecord: builder.query({
        //     query: (id) =>`/warehouse-refund-record/${id}`,
        // }),
    })
});

export const {
  useGetProdcutByStoreQuery,
  useGetProdcutQuery,
  useAddRefundMutation,
  useGetRefundRecordQuery,
  useGetRefundRecordByDateMutation,
  useGetCheckInOutMutation,
 } = ProductApiSlice